import { accessor } from "@/store";
import { PluginObject } from "vue";
import { ApiClient } from "./api-client";
import { handleApiError } from "./handle-api-error";

export const ApiPlugin: PluginObject<unknown> = {
  install(Vue) {
    Vue.prototype.$api = new ApiClient();
    Vue.prototype.$handleApiError = handleApiError;
  },
};
