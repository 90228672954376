import Vue from "vue";
import createAuth0Client, {
  Auth0Client,
  LogoutOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import { CombinedVueInstance } from "vue/types/vue";

export interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  audience: string;
  onRedirectCallback?: (appState?: AppState) => void;
  redirectUri?: string;
}

interface AppState {
  targetUrl?: string;
}

type Instance = CombinedVueInstance<Vue, Data, Methods, unknown, unknown>;

const DEFAULT_REDIRECT_CALLBACK = () => {
  return window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  );
};

let instance: Instance;

export interface Methods {
  loginWithRedirect(options?: RedirectLoginOptions): Promise<void>;
  logout(options?: LogoutOptions): Promise<void> | void;
  getAccessToken(): Promise<string | null>;
}

export interface Data {
  isAuthenticated: boolean;
  auth0Client: Auth0Client | null;
  error: unknown | null;
}

export type Auth0Plugin = Data & Methods;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: Auth0PluginOptions): Instance => {
  if (instance) return instance;

  instance = new Vue<Data, Methods>({
    data(): Data {
      return {
        isAuthenticated: false,
        auth0Client: null,
        error: null,
      };
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        ...options,
        audience: options.audience,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      });

      try {
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } =
            await this.auth0Client.handleRedirectCallback<AppState>();
          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          this.$accessor.auth.loginWithAuth0();
          if (process.env.NODE_ENV !== "production") {
            console.log("[token]", await this.auth0Client.getTokenSilently());
          }
        }
        this.$accessor.auth.loaded();
      }
    },
    methods: {
      async loginWithRedirect(options?: RedirectLoginOptions): Promise<void> {
        return this.auth0Client?.loginWithRedirect(options);
      },
      async logout(options?: LogoutOptions): Promise<void> {
        return this.auth0Client?.logout(options);
      },
      async getAccessToken(): Promise<string | null> {
        return this.auth0Client?.getTokenSilently() ?? null;
      },
    },
  });

  return instance;
};
