






import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    maxWidth: {
      type: Number,
    },
  },
});
