export const brandMap = new Map([
  ["BENTLEY", "Bentley"],
  ["FERRARI", "Ferrari"],
  ["LAMBO", "Lamborghini"],
  ["ROLLSROYCE", "Rolls-Royce"],
  ["Porsche", "Porsche"],
  ["McLaren", "McLaren"],
  ["Aston Martin", "Aston Martin"],
  ["Maserati", "Maserati"],
  ["Mercedes AMG", "Mercedes AMG"],
  ["BMW M Series", "BMW M Series"],
]);

export const brandMapper = {
  get(key: string): string {
    return brandMap.get(key) ?? key;
  },
};
