import Vue from "vue";
import { CombinedVueInstance } from "vue/types/vue";
import { Auth0PluginOptions, useAuth0 } from "./auth0";
import { RedirectLoginOptions } from "@auth0/auth0-spa-js";
import router from "@/router";

interface Methods {
  loginWithAuth0(options?: RedirectLoginOptions): Promise<void>;
  loginWithHash(hash: string): Promise<void>;
  logout(): Promise<void>;
  getAccessToken(): Promise<string | null>;
}

interface Computed {
  isLoggedIn: boolean;
  loading: boolean;
}

type Instance = CombinedVueInstance<Vue, unknown, Methods, Computed, unknown>;

let instance: Instance;

export const getAuthInstance = (): Instance => instance;

export type AuthPlugin = Methods & Computed;

export const useAuth = (options: Auth0PluginOptions): Instance => {
  if (instance) return instance;

  const auth0Instance = useAuth0(options);

  instance = new Vue<unknown, Methods, Computed>({
    methods: {
      async loginWithAuth0(options?: RedirectLoginOptions): Promise<void> {
        return auth0Instance.loginWithRedirect(options);
      },
      async loginWithHash(hash: string): Promise<void> {
        return this.$accessor.auth.loginWithHash(hash);
      },
      async logout(): Promise<void> {
        if (auth0Instance.isAuthenticated) {
          return await auth0Instance.logout({
            returnTo: window.location.origin,
          });
        }

        this.$accessor.auth.logout();
        router.push("/");
      },
      async getAccessToken(): Promise<string | null> {
        switch (this.$accessor.auth.loginType) {
          case "auth0":
            return await auth0Instance.getAccessToken();
          case "hash":
            return this.$accessor.auth.hash;
          default:
            return null;
        }
      },
    },
    computed: {
      isLoggedIn(): boolean {
        return this.$accessor.auth.isLoggedIn;
      },
      loading(): boolean {
        return this.$accessor.auth.loading;
      },
    },
  });

  return instance;
};
