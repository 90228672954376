import { actionTree, getterTree, mutationTree } from "typed-vuex";

export interface AuthState {
  loginType: "auth0" | "hash" | null;
  isLoggedIn: boolean;
  hash: string | null;
  loading: boolean;
}

export const state = (): AuthState => ({
  loginType: null,
  isLoggedIn: false,
  hash: null,
  loading: true,
});

export const getters = getterTree(state, {
  loginType: (state) => state.loginType,
  isLoggedIn: (state) => state.isLoggedIn,
  hash: (state) => state.hash,
  loading: (state) => state.loading,
});

export const mutations = mutationTree(state, {
  ["LOGIN_WITH_AUTH0"]: (state) => {
    state.loginType = "auth0";
    state.isLoggedIn = true;
  },
  ["LOGIN_WITH_HASH"]: (state, newHash: string) => {
    state.loginType = "hash";
    state.isLoggedIn = true;
    state.hash = newHash;
  },
  ["LOGOUT"]: (state) => {
    state.loginType = null;
    state.isLoggedIn = false;
    state.hash = null;
  },
  ["LOADED"]: (state) => {
    state.loading = false;
  },
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    loginWithAuth0: ({ commit }) => {
      commit("LOGIN_WITH_AUTH0");
    },
    loginWithHash: ({ commit }, newHash: string) => {
      commit("LOGIN_WITH_HASH", newHash);
    },
    logout: ({ commit }) => {
      commit("LOGOUT");
    },
    loaded: ({ commit }) => {
      commit("LOADED");
    },
  }
);
