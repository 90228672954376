



















import { defineComponent } from "@vue/composition-api";
import BaseContainer from "@/components/base/BaseContainer.vue";
import NativeAppLayout from "@/layouts/NativeAppLayout.vue";
import ThePageTitle from "@/components/global/ThePageTitle.vue";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "CreateAccountThanks",
  components: {
    BaseContainer,
    NativeAppLayout,
    ThePageTitle,
  },
  setup() {
    return {
      v$: useVuelidate({
        $scope: false,
      }),
    };
  },
});
