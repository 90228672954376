




























import { defineComponent } from "@vue/composition-api";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BaseContainer from "@/components/base/BaseContainer.vue";
import FormContainer from "@/components/form/FormContainer.vue";
import NativeAppLayout from "@/layouts/NativeAppLayout.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import ThePageTitle from "@/components/global/ThePageTitle.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, maxLength } from "@/lib/validators";
interface Data {
  form: Nullable<{
    email: string;
  }>;
}
export default defineComponent({
  name: "CreateAccount",
  components: {
    BaseContainer,
    BaseTextField,
    FormContainer,
    NativeAppLayout,
    SubmitButton,
    ThePageTitle,
  },
  setup() {
    return {
      v$: useVuelidate({
        $scope: false,
      }),
    };
  },

  data(): Data {
    return {
      form: {
        email: null,
      },
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: required("メールアドレス"),
          email: email("メールアドレス"),
          maxLength: maxLength(255),
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      const result = await this.$api.postCreateAccount({
        email: this.$data.form.email as string,
      });
      if (result.isFailure()) {
        return this.$handleApiError(result.value);
      }
      this.$router.push("/app/account/thanks");
    },
  },
});
