import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { apiRequestTimeout } from "@/config/app";
import { accessor } from "@/store";
import { getAuthInstance } from "@/auth";

export type Either<L, R> = Failure<L, R> | Success<L, R>;

class Failure<L, R> {
  readonly value: L;
  constructor(value: L) {
    this.value = value;
  }

  isFailure(): this is Failure<L, R> {
    return true;
  }
  isSuccess(): this is Success<L, R> {
    return false;
  }
}

class Success<L, R> {
  readonly value: R;
  constructor(value: R) {
    this.value = value;
  }

  isFailure(): this is Failure<L, R> {
    return false;
  }
  isSuccess(): this is Success<L, R> {
    return true;
  }
}

export const fail = <L, R>(l: L): Either<L, R> => {
  return new Failure<L, R>(l);
};

export const succeed = <L, R>(r: R): Either<L, R> => {
  return new Success<L, R>(r);
};

export type FailureResponse = {
  error: string;
  data: null;
  statusCode: number;
};

export type SuccessResponse<T> = {
  error: null;
  data: T;
  statusCode: number;
};

export type ApiResponse<T> = Either<FailureResponse, SuccessResponse<T>>;

/**
 * axios基底クラス
 */
export abstract class AxiosBaseClient {
  private readonly axiosInstance: AxiosInstance;

  protected constructor() {
    const config: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_API_BASE_URL ?? "",
      timeout: apiRequestTimeout,
    };

    const instance = axios.create(config);
    this.axiosInstance = instance;
    this.enableInterceptors();
  }

  public getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  /**
   * intercept
   */
  private enableInterceptors() {
    this.axiosInstance.interceptors.request.use(async (config) => {
      const auth = getAuthInstance();
      const accessToken = await auth.getAccessToken();
      if (accessToken) {
        (config.headers ??= {}).Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });
  }

  /**
   * get request
   * @param {string} url
   * @param {Req} queries
   */
  protected getRequest = async <Res, Req = unknown>(
    url: string,
    queries?: Req extends Record<string, unknown>
      ? RecursiveNullable<Req>
      : Req,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<Res>> => {
    const mergedConfig: AxiosRequestConfig = {
      ...config,
      params: queries,
    };

    try {
      const result = await this.axiosInstance.get<Res>(url, mergedConfig);
      return succeed({
        data: result.data,
        error: null,
        statusCode: result.status,
      });
    } catch (e: unknown) {
      return await this.processError(e);
    }
  };

  /**
   * post request
   * @param {string} url
   * @param {Req} data
   */
  protected postRequest = async <Res, Req = unknown>(
    url: string,
    data?: Req extends Record<string, unknown> ? RecursiveNullable<Req> : Req,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<Res>> => {
    try {
      const result = await this.axiosInstance.post<Res>(url, data, config);
      return succeed({
        data: result.data,
        error: null,
        statusCode: result.status,
      });
    } catch (e: unknown) {
      return await this.processError(e);
    }
  };

  private async processError<Res>(e: unknown): Promise<ApiResponse<Res>> {
    console.error(e);
    let status = 500;
    let message = "予期せぬエラーが発生しました。";

    if (axios.isAxiosError(e)) {
      status = e.response?.status ?? status;
      message = e.response?.data?.message ?? e.message ?? message;
      if (Array.isArray(message)) {
        message = message.join(", ");
      }
    } else if (e instanceof Error) {
      message = e.message;
    }
    if (status === 401) {
      const auth = getAuthInstance();
      await auth.logout();
    }

    return fail({
      data: null,
      error: message,
      statusCode: status,
    });
  }

  protected getCorrespondingPath(path: string): string {
    if (accessor.auth.loginType === "hash") {
      return path + "/hash";
    }
    return path;
  }
}
