








import { defineComponent } from "@vue/composition-api";
import { Route } from "vue-router";

export default defineComponent({
  name: "App",
  methods: {
    setMeta(route: Route) {
      // タイトルを設定
      if (route.meta?.title) {
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if (route.meta?.description) {
        let setDesc = route.meta.description;
        document
          .querySelector("meta[name='description']")
          ?.setAttribute("content", setDesc);
      }
    },
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    $route(route: Route) {
      this.setMeta(route);
    },
  },
});
