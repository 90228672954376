import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import Member from "../views/Member/Index.vue";
// import MemberConsent from "../views/Member/Consent.vue";
// import Home from "../views/Home.vue";
// import MemberThanks from "../views/Member/Thanks.vue";
// import Thanks from "../views/Thanks.vue";
import CreateAccount from "../views/NativeApp/Account/index.vue";
import CreateAccountThanks from "../views/NativeApp/Account/thanks.vue";

// import { authGuard } from "@/auth/authGuard";
import { accessor } from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // 事前登録は終了したので，関連するパスはコメントアウト
  // {
  //   path: "/",
  //   name: "home",
  //   component: Home,
  //   meta: {
  //     title: "メールアドレスご登録フォーム | CORNESアプリ事前登録",
  //   },
  // },
  // {
  //   path: "/thanks",
  //   name: "thanks",
  //   component: Thanks,
  //   meta: {
  //     title: "メールアドレス登録完了 | CORNESアプリ事前登録",
  //   },
  // },
  // {
  //   path: "/callback",
  //   name: "callback",
  // },
  // {
  //   path: "/member",
  //   name: "member",
  //   component: Member,
  //   beforeEnter: authGuard,
  //   meta: {
  //     title: "CORNESアプリ事前登録",
  //   },
  // },
  // {
  //   path: "/member/consent",
  //   name: "member-consent",
  //   component: MemberConsent,
  //   beforeEnter: authGuard,
  //   meta: {
  //     title: "所有車両の公開設定 | CORNESアプリ事前登録",
  //   },
  // },
  // {
  //   path: "/member/thanks",
  //   name: "member-thanks",
  //   component: MemberThanks,
  //   beforeEnter: authGuard,
  //   meta: {
  //     title: "登録完了 | CORNESアプリ事前登録",
  //   },
  // },
  {
    path: "/app/account",
    name: "create-account",
    component: CreateAccount,
    meta: {
      title: "アカウント発行",
    },
  },
  {
    path: "/app/account/thanks",
    name: "create-account-thanks",
    component: CreateAccountThanks,
    meta: {
      title: "送信完了",
    },
  },
  { path: "*", redirect: { path: "/app/account" } },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.afterEach(() => {
  accessor.app.unlockSubmission();
});

export default router;
