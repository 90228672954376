










import { defineComponent } from "@vue/composition-api";
import TheHeader from "@/components/global/HeaderNativeApp.vue";

export default defineComponent({
  components: { TheHeader },
  props: {
    backgroundColor: {
      type: String,
      default: "primary",
    },
  },
});
