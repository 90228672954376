













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    spacingX: {
      type: Boolean,
      default: true,
    },
    spacingY: {
      type: Boolean,
      default: false,
    },
  },
});
