












import { defineComponent, PropType } from "@vue/composition-api";
import { ErrorObject } from "@vuelidate/core";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    errorMessages: {
      type: Array as PropType<ErrorObject[]>,
      default: () => [],
    },
  },
  methods: {
    handleBlur($event: FocusEvent) {
      this.$emit("blur", $event);
      this.$emit("touch");
    },
  },
  computed: {
    _errorMessages(): string[] {
      return this.errorMessages.map((error) => error.$message as string);
    },
  },
});
