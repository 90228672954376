import { OwnedCarWithSfId } from "@/types/app";
import { actionTree, getterTree, mutationTree } from "typed-vuex";

export interface DataState {
  selectedOwnedCar: OwnedCarWithSfId[];
}

export const state = (): DataState => ({
  selectedOwnedCar: [],
});

export const getters = getterTree(state, {
  selectedOwnedCar: (state: DataState) => state.selectedOwnedCar,
});

export const mutations = mutationTree(state, {
  ["SET_SELECTED_OWNED_CARS"]: (state, payload: OwnedCarWithSfId[]) =>
    (state.selectedOwnedCar = payload),
  ["CLEAR_SELECTED_OWNED_CARS"]: (state) => (state.selectedOwnedCar = []),
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    setSelectedOwnedCars({ commit }, payload: OwnedCarWithSfId[]) {
      commit("SET_SELECTED_OWNED_CARS", payload);
    },
    clearSelectedOwnedCars({ commit }) {
      commit("CLEAR_SELECTED_OWNED_CARS");
    },
  }
);
