


















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "button",
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Number,
    },
  },
});
