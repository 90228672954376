<template>
  <base-box :maxWidth="330">
    <slot />
  </base-box>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import BaseBox from "../base/BaseBox.vue";

export default defineComponent({
  components: { BaseBox },
});
</script>
