import { actionTree, getterTree, mutationTree } from "typed-vuex";

export interface AppState {
  canSubmit: boolean;
  isAsyncLoading: boolean;
}

export const state = (): AppState => ({
  canSubmit: true,
  isAsyncLoading: false,
});

export const getters = getterTree(state, {
  canSubmit: (state: AppState) => state.canSubmit,
  isAsyncLoading: (state: AppState) => state.isAsyncLoading,
});

export const mutations = mutationTree(state, {
  ["LOCK_SUBMISSION"]: (state) => (state.canSubmit = false),
  ["UNLOCK_SUBMISSION"]: (state) => (state.canSubmit = true),
  ["SHOW_ASYNC_LOADING"]: (state) => (state.isAsyncLoading = true),
  ["HIDE_ASYNC_LOADING"]: (state) => (state.isAsyncLoading = false),
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    lockSubmission({ commit }) {
      commit("LOCK_SUBMISSION");
    },
    unlockSubmission({ commit }) {
      commit("UNLOCK_SUBMISSION");
    },
    showAsyncLoading({ commit }) {
      commit("SHOW_ASYNC_LOADING");
    },
    hideAsyncLoading({ commit }) {
      commit("HIDE_ASYNC_LOADING");
    },
  }
);
