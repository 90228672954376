import { useAccessor } from "typed-vuex";
import Vue from "vue";
import Vuex from "vuex";
import * as app from "./app";
import * as auth from "./auth";
import * as data from "./data";

Vue.use(Vuex);

const storePattern = {
  modules: { app, auth, data },
};

const store = new Vuex.Store(storePattern);

export const accessor = useAccessor(store, storePattern);

Vue.prototype.$accessor = accessor;

export default store;
