










import { defineComponent } from "@vue/composition-api";
import BaseButton from "@/components/base/BaseButton.vue";

export default defineComponent({
  components: {
    BaseButton,
  },
  methods: {
    handleClick($event: unknown) {
      this.$accessor.app.lockSubmission();
      this.$emit("click", $event);
    },
  },
  computed: {
    _disabled(): boolean {
      if (this.useLock) {
        return (
          !this.$accessor.app.canSubmit ||
          this.$accessor.app.isAsyncLoading ||
          this.disabled
        );
      } else {
        return this.disabled;
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    useLock: {
      type: Boolean,
      default: true,
    },
  },
});
