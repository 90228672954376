import { accessor } from "@/store";

export interface HandleApiErrorConfig {
  type?: "warning" | "danger";
  redirect?: string;
}

export interface ErrorObject {
  error?: string;
  statusCode?: number;
}

export type HandleApiError = (
  { error, statusCode }: ErrorObject,
  config?: HandleApiErrorConfig
) => void;

export const handleApiError: HandleApiError = ({ error }): void => {
  // const defaultConfig: HandleApiErrorConfig = {
  //   type: "warning",
  // };
  // const mergedConfig = Object.assign({}, defaultConfig, config);
  // mergedConfig;

  error && alert(error);

  if (!accessor.app.canSubmit) {
    accessor.app.unlockSubmission();
  }
};
