import {
  GetUsedCarsOwnedResponse,
  GetUsersResponse,
  PostCarPostsConsentRequestBody,
  PostCreateAccountRequestBody,
  PostUsedCarsOwnedRequestBody,
  PostUsersEditRequestBody,
  PostUsersRegisterRequestBody,
  PostUsersRegisterResponse,
} from "@/types/api";
import { ApiResponse, AxiosBaseClient } from "./base-client";

export class ApiClient extends AxiosBaseClient {
  private readonly apiUrl = "";
  constructor() {
    super();
    // ネイティブアプリから表示する場合のAPIのコール先
    this.apiUrl = process.env.VUE_APP_API_URL ?? "";
  }

  getUsers = async (): Promise<ApiResponse<GetUsersResponse>> => {
    return await this.getRequest(this.getCorrespondingPath("/users"));
  };

  getUsedCarsOwned = async (): Promise<
    ApiResponse<GetUsedCarsOwnedResponse>
  > => {
    return await this.getRequest(this.getCorrespondingPath("/used-cars/owned"));
  };

  postUsersEdit = async (
    data: Nullable<PostUsersEditRequestBody>
  ): Promise<ApiResponse<void>> => {
    return await this.postRequest<void, PostUsersEditRequestBody>(
      this.getCorrespondingPath("/users/edit"),
      data
    );
  };

  postUsedCarsOwned = async (
    data: RecursiveNullable<PostUsedCarsOwnedRequestBody>
  ): Promise<ApiResponse<void>> => {
    return await this.postRequest<void, PostUsedCarsOwnedRequestBody>(
      this.getCorrespondingPath("/used-cars/owned"),
      data
    );
  };

  postCarPostsConsent = async (
    data: RecursiveNullable<PostCarPostsConsentRequestBody>
  ): Promise<ApiResponse<void>> => {
    return await this.postRequest<void, PostCarPostsConsentRequestBody>(
      this.getCorrespondingPath("/car-posts/consent"),
      data
    );
  };

  postCompleteMail = async (): Promise<ApiResponse<void>> => {
    return await this.postRequest<void>(
      this.getCorrespondingPath("/complete/mail")
    );
  };

  postUsersRegister = async (
    data: Nullable<PostUsersRegisterRequestBody>
  ): Promise<ApiResponse<PostUsersRegisterResponse>> => {
    return await this.postRequest<
      PostUsersRegisterResponse,
      PostUsersRegisterRequestBody
    >("/users/register", data);
  };

  postCreateAccount = async (
    data: PostCreateAccountRequestBody
  ): Promise<ApiResponse<void>> => {
    return await this.postRequest<void>("/users/create", data, {
      baseURL: this.apiUrl,
    });
  };
}
