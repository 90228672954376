import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api";
import { AuthPlugin } from "./plugins/auth";
import { ApiPlugin } from "./plugins/api";
import "./styles/index.scss";
import "./assets/icomoon/style.css";
import { AsyncLoadingPlugin } from "./plugins/asyncLoading";
import { MapperPlugin } from "./plugins/mapper";

if (process.env.NODE_ENV !== "production") {
  console.log(`[App] Running on ${process.env.NODE_ENV} mode.`);
  console.log(`[App] loaded env file is ${process.env.VUE_APP_ENV_FILE_NAME}`);
}

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(AuthPlugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.use(ApiPlugin);
Vue.use(AsyncLoadingPlugin);
Vue.use(MapperPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
