import { accessor } from "@/store";
import { PluginObject } from "vue";

/**
 * loading 再生開始
 */
const startLoading = (): void => {
  accessor.app.showAsyncLoading();
};

/**
 * loading 再生停止
 */
const finishLoading = (): void => {
  accessor.app.hideAsyncLoading();
};

export const asyncLoading = async <T>(
  fn: (...args: unknown[]) => Promise<T>,
  ...args: unknown[]
): Promise<T> => {
  startLoading();
  const result: T = await fn(...args);
  finishLoading();

  return result;
};

export const AsyncLoadingPlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$asyncLoading = asyncLoading;
  },
};
